import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/LifeSkill2023/interactive-sept-2023/1.jpg';
// import p2 from 'assests/Photos/LifeSkill2023/interactive-sept-2023/2.jpg';
// import p3 from 'assests/Photos/LifeSkill2023/interactive-sept-2023/3.jpg';
// import p4 from 'assests/Photos/LifeSkill2023/interactive-sept-2023/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from 'views/LifeSkill2023/Sidebar2023';

const InteractiveLife2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/interactive-sept-2023/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/interactive-sept-2023/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/interactive-sept-2023/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/life-skill/interactive-sept-2023/4.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      An Insight into the Field of Medicine
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 11 - 12  &  Date: 2 SEPTEMBER 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        A guest lecture was organized on Saturday, the 2nd of September at National Public School, Yeshwanthpur, Bangalore by the Department of Chemistry with the purpose to acquaint the students about the process which goes behind the making of drugs before they are released into the market. Our expert, Dr Purushottam, is a renowned scientist and entrepreneur with a PhD in Organic Chemistry.
        <br></br>
        The session started with a discussion on “How does the body differentiate between similar looking tablets consumed by the patient?” For which Dr Dewang explained the topic of Molecular Target, in which scientists produce keys (medicines) for particular locks present in our body (receptors), this is known as the lock and key model. He also explained the induced-fit model and other mechanisms by which drugs react with the body. The discussion then proceeded from idea generation of a drug, followed by target identification, target validation, hit identification, lead optimisation and 4-5 more steps for pre-clinical and clinical trials. Dr Dewang simplified this several stepped process and explained in detail the mechanism of how drugs respond to the body i.e. pharmacodynamics and how our body reacts to the drug i.e. pharmacokinetics.
        <br></br>
        The students participated actively and it was evident in the depth of queries which were put forth to the expert in order to increase their understanding. The learning outcome was the in-depth and detailed understanding of the process of drug discovery. Dr Dewang’s lecture instilled inquisitiveness among students and inspired them to take up research as a career.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Secrets of Discovering New Medicines”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default InteractiveLife2023;