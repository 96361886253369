/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  {
    
    description:
      'Interactive Session July 2023',
      href : '/life-skill-2023'
  },
  {
      
    description:
      'Interactive Session September 2023',
      href : '/interactive-sept-2023'
  },
  {
      
    description:
      'IISC Workshop',
      href : '/iisc-workshop-2023'
  },
  {
      
    description:
      ' Emotional Regulation',
      href : '/EmotionalRegulation'
  },
  {
      
    description:
      ' Workshop on First Aid Demonstration',
      href : '/FirstAidDemo'
  },
  {
      
    description:
      ' National Road Safety',
      href : '/national-road-2023'
  },
  {
      
    description:
      ' Road Safety Week',
      href : '/road-safety-2023'
  },
  {
      
    description:
      ' Good & Bad Touch Awareness Program.',
      href : '/awareness-program-on-touch-2023'
  },
  {
      
    description:
      ' Career Guidance Session',
      href : '/careerguidance-2023'
  },
  
];

const Sidebar2023 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Life Skill 2023-24
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2023;
